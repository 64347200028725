<template>
    <div class="flex column center" style="margin-bottom:4rem;">
        <div class="new_box">
            <div class="titlee">国家禁毒办：将整类合成大麻素类物质列入管制</div>
            <p class="time">2021-05-21</p>
            <div class="space_content content_">
              2021年5月11日，国家禁毒委员会办公室举行的新闻发布会介绍，公安部、国家卫生健康委员会和国家药品监督管理局联合发布《关于将合成大麻素类物质和氟胺酮等18种物质列入〈非药用类麻醉药品和精神药品管制品种增补目录〉的公告》，决定正式整类列管合成大麻素类新精神活性物质，并新增列管氟胺酮等18种新精神活性物质。自2021年7月1日起施行。
              </div>
              <div class="space_content content_">至此，我国已列管188种新精神活性物质和整类芬太尼、整类合成大麻素物质。
              </div>
              <div class="space_content content_">国家禁毒办表示，将整类合成大麻素类物质和氟胺酮等18种新精神活性物质列为毒品进行管制。这是继芬太尼整类列管后，中国再次整类列管一类新精神活性物质。据了解，新精神活性物质具有与管制毒品相似或更强的兴奋、致幻、麻醉等效果。
              </div>
              <div class="space_content content_">1 2001年，氯胺酮被列入第二类精神药品进行管制，2005年调至第一类精神药品进行管制。13 存在于《麻醉药品和精神药品目录》之中，2010年以来，我国及时将国际社会反映突出的四甲基甲卡西酮等13种新精神活性物质先后列入《麻醉药品和精神药品目录》。  116 是指2015年10月1日起实施的《非药用类麻醉药品和精神药品列管办法》，一次性列管116种新精神活性物质。4 是2017年3月1日起，卡芬太尼等4种芬太尼类物质列入管制。4 是2017年7月1日起，U-47700等四种新精神活性物质列入增补目录。32 是2018年9月1日起被列管的4-氯乙卡西酮等物质。18 2021年7月，整类列管合成大麻素类物质，新增列管氟胺酮等18种物质。 至此,“ 1+13+116+4+4+32+18 ”,我国列管的新精神活性物质已达188种，并对芬太尼类物质、合成大麻素类物质施行整类列管。
              </div>
              <div class="space_content content_">合成大麻素经常通过添加至电子烟油或者使用烟叶浸泡后吸食，其药效是植物大麻20倍以上，滥用性、成瘾性更强。
              </div>
              <div class="space_content content_"> 我所具备对上述合成大麻素、小树枝、娜塔莎等新精神活性物质进行鉴定的资质和能力。
              </div>
              <div class="space_content content_"> 历思是国内禁毒领域领先的司法鉴定机构，在血液、尿液、毛发、成品、原料、环境的毒检方面均有相应研究；近年来承担了大量上述新型毒品案件鉴定工作，与省内各地市警方就打击制贩毒、查吸、在册人员管控、毒情监测分析、毒情逆向追踪等展开系统性合作。
              </div>
            
        </div>
        <div class="flex wrap col442" style="width:100%;text-align:left;margin-top:1rem">
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex" style="margin-right:10%">
                    <p style="white-space:nowrap">上一篇：</p>无
                    
                </div>
            </div>
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex">
                    <p style="white-space:nowrap">下一篇：</p>
                    <router-link class="a" :to="'/news/industrynews/2'"><p class="ellipsis1">伤后多长时间可以进行伤残鉴定？</p></router-link>
                    
                </div>
            </div>
            <div class="col2 flex  end1 center">
                <router-link class="a" style="color:#fff" :to="'/news/industrynews'"><el-button type="primary">返回列表</el-button></router-link >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return { 
            data:{

            }
        }
    },
}
</script>